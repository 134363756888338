<!-- eslint-disable vue/multi-word-component-names -->
<script>
import Filtro05 from '@/components/shared/relatorio/filtros/Filtro05.vue'

export default {
  extends: Filtro05,

  data() {
    return {
      modulo: 'consignataria',
    }
  },

  methods: {
    carregarListaEntidades() {
      this.entidadeService.getListaEntidadesPorUsuario().then((res) => {
        this.entidades = res
      })
    },

    carregarListaConsignatarias() {
      this.consignatariaService
        .getConsignatariaPorRelatorio('PrestacoesDescontadas')
        .then((res) => {
          this.consignatarias = res
        })
    },
  },
}
</script>
